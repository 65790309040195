<template>
  <div class="d-flex font-rem-0-6 mt-0 mb-1">
    <inline-svg
      class="align-self-center"
      :src="srcComputed"
      width="15px"
      height="15px"
      fill="white"
      aria-label="Loading..."
    />
    <span>
      {{ full ? String(full.values[0]).slice(0, 5) : "" }}
      {{ info ? String(info.units).slice(0, 7) : "" }}</span
    >
    <span :class="classComputed"
      >{{ subtraction > 0 ? "&#x25B2;" : "&#x25BC;" }}
    </span>
  </div>
</template>

<script>
export default {
  mounted() {},
  props: ["full"],
  computed: {
    info() {
      if (this.full && this.full.meassurement_info) {
        const { meassurement_info } = this.full
        const info = meassurement_info[0]
        return info
      } else {
        return { name: "light" }
      }
    },
    srcComputed() {
      let src = ""
      switch (this.info.name) {
        case "light":
          src = "/luz.svg"
          break
        case "temperature":
          src = "/temperatura.svg"
          break
        case "humidity":
          src = "/humedad.svg"
      }
      return src
    },
    subtraction() {
      if (this.full) {
        const { values } = this.full
        const subtraction = values[0] - values[1]
        return subtraction
      }
      return 0
    },
    classComputed() {
      if (this.full && this.full.values) {
        return this.subtraction > 0 ? "color-light-green" : "color-light-red"
      } else {
        return "color-light-green"
      }
    }
  }
}
</script>

<style></style>
